import { OrderListPage } from "../../components"
import { PointChargeNoticeModal } from "../../components/points/PointChargeNoticeModal"

export default function MainPage() {
  return (
    <div className="relative">
      <OrderListPage />
      <PointChargeNoticeModal />
    </div>
  )
}
